var CURRENT_URL = window.location.href.split('?')[0],
    $BODY = $('body'),
    $MENU_TOGGLE = $('#menu_toggle'),
    $SIDEBAR_MENU = $('#sidebar-menu'),
    $SIDEBAR_FOOTER = $('.sidebar-footer'),
    $LEFT_COL = $('.left_col'),
    $RIGHT_COL = $('.right_col'),
    $NAV_MENU = $('.nav_menu'),
    $FOOTER = $('footer');

$MENU_TOGGLE.on("click", function() {
	
    if($BODY.hasClass("nav-md")) {
    	$SIDEBAR_MENU.find("li.active ul").hide(),
    	$SIDEBAR_MENU.find("li.active").addClass("active-sm").removeClass("active"),
    	$BODY.find(".site_logo").attr("src","/images/tor-logo-min.png").addClass('sm')
    } else {
    	$SIDEBAR_MENU.find("li.active-sm ul").show(),
    	$SIDEBAR_MENU.find("li.active-sm").addClass("active").removeClass("active-sm"),
    	$BODY.find(".site_logo").attr("src","/images/tor-logo.png").removeClass('sm')
    }
})